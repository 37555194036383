import { type FC, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { useGroupsApi } from '@hooks/api/groups';
import { type Organization } from '@hooks/api/organizations';
import { useOrganizationsApiTotalCount } from '@hooks/api/organizations/useOrganizationsApiTotalCount';
import { useOrganizationsPaginatedApi } from '@hooks/api/organizations/useOrganizationsPaginatedApi';
import { type Project, type ProjectWithOrganizations } from '@hooks/api/projects';
import { useProjectsPaginatedApi } from '@hooks/api/projects/useProjectsPaginatedApi';
import { useProjectsUrlSync } from '@hooks/api/projects/useProjectsUrlSync';
import useDebounce from '@hooks/api/useDebounce';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { OverviewPageLayout } from '@pages/common';

import { ProjectsFilterBar } from './ProjectsFilterBar';
import { ProjectsTable } from './ProjectsTable';

export const ProjectsOverview: FC = () => {
  const { t } = useTranslation();
  const { isItemVisible } = useRenderConfig();

  const {
    page,
    size,
    sortModel,
    name,
    generalContractorId,
    networkProviderId,
    customerManager,
    customerContactPerson,
    serviceProviderId,
    networkDesignState,
    networkDesignFormat,
    activityState,
    pipeDirection,
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
    handleNetworkProviderIdChange,
    handleGeneralContractorIdChange,
    handleCustomerManagerChange,
    handleCustomerContactPersonChange,
    handleServiceProviderIdChange,
    handleNetworkDesignStateChange,
    handleNetworkDesignFormatChange,
    handleActivityStateChange,
    handlePipeDirectionChange,
  } = useProjectsUrlSync();

  const debouncedName = useDebounce(name);
  const totalOrganizationCount = useOrganizationsApiTotalCount() || 0;

  const {
    data: projectsData,
    error: projectsError,
    isValidating: isValidatingProjects,
  } = useProjectsPaginatedApi({
    page,
    size,
    sortModel,
    name: debouncedName,
    networkProviderId,
    generalContractorId,
    customerManager,
    customerContactPerson,
    serviceProviderId,
    networkDesignState,
    networkDesignFormat,
    activityState,
    pipeDirection,
  });
  const {
    data: organizationsData,
    error: organizationsError,
    isValidating: isValidatingOrganizations,
  } = useOrganizationsPaginatedApi({
    page,
    size: totalOrganizationCount,
  });
  const { data: groupsData } = useGroupsApi({
    isDeepUpOrg: true,
  });

  const hasError = projectsError || organizationsError;
  const isLoadingData =
    !projectsData || !organizationsData || isValidatingProjects || isValidatingOrganizations;

  const projectsWithOrganizationInfo: ProjectWithOrganizations[] = projectsData?.content
    ? projectsData.content.map((project: Project) => {
        const findOrgaById = (orgaId?: string | null): Organization | undefined =>
          orgaId
            ? organizationsData?.content.find(
                (organization: Organization) => organization.id === orgaId,
              )
            : undefined;

        const organization = findOrgaById(project.organizationId) as Organization;
        const networkProvider = findOrgaById(project.networkProviderId);
        const generalContractor = findOrgaById(project.generalContractorId);

        return {
          ...project,
          organization,
          networkProvider,
          generalContractor,
        } as ProjectWithOrganizations;
      })
    : [];

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    const searchString = event.currentTarget.value;

    handleNameChange(searchString);
  };

  const rowCount = projectsData?.totalElements || 0;
  const hasCreateButton = isItemVisible('projects.overview.components.btnCreate');
  const hasNoProjects = projectsData?.content.length === 0;
  const isResultEmpty = (projectsWithOrganizationInfo ?? []).length === 0;

  if (hasError) return <div>failed to load</div>;

  return (
    <OverviewPageLayout
      createRoute={hasCreateButton ? '/projects/create' : ''}
      createTitle={t('pages.projects.overview.components.btnCreate')}
      title={t('pages.projects.overview.title')}
    >
      <>
        <ProjectsFilterBar
          activityState={activityState}
          customerContactPerson={customerContactPerson}
          customerManager={customerManager}
          generalContractorId={generalContractorId}
          groups={groupsData?.content ?? []}
          networkDesignFormat={networkDesignFormat}
          networkDesignState={networkDesignState}
          networkOperatorId={networkProviderId}
          onChangeActivityState={handleActivityStateChange}
          onChangeCustomerContactPerson={handleCustomerContactPersonChange}
          onChangeCustomerManager={handleCustomerManagerChange}
          onChangeGeneralContractorId={handleGeneralContractorIdChange}
          onChangeName={handleChangeName}
          onChangeNetworkDesignFormat={handleNetworkDesignFormatChange}
          onChangeNetworkDesignState={handleNetworkDesignStateChange}
          onChangeNetworkOperatorId={handleNetworkProviderIdChange}
          onChangePipeDirection={handlePipeDirectionChange}
          onChangeServiceProviderId={handleServiceProviderIdChange}
          organizations={organizationsData?.content ?? []}
          pipeDirection={String(pipeDirection)}
          searchName={name}
          serviceProviderId={serviceProviderId}
        />
        {!isLoadingData && (isResultEmpty || hasNoProjects) ? (
          <EmptyTableInfo info={t('pages.projects.overview.components.table.emptyInfo')} />
        ) : (
          <ProjectsTable
            isLoading={isLoadingData}
            onPageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            onSortModelChange={handleSortModelChange}
            page={page}
            projects={projectsWithOrganizationInfo}
            rowCount={rowCount}
            searchName={debouncedName}
            size={size}
            sortModel={sortModel || []}
          />
        )}
      </>
    </OverviewPageLayout>
  );
};
