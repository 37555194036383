import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import { useForm, useFormState, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BottomFormNavigation } from '@components/BottomFormNavigation';
import { useOrganizationsApi } from '@hooks/api/organizations';
import type { Project } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { useFormProjectState } from '@pages/Projects/useFormProjectState';

import {
  ProjectsFormActivityState,
  ProjectsFormConnections,
  ProjectsFormContactPerson,
  ProjectsFormCustomerContactPerson,
  ProjectsFormCustomerManager,
  ProjectsFormMasterData,
  ProjectsFormNetworkDesign,
  ProjectsFormPipeDirection,
  ProjectsFormServiceProvider,
} from './ProjectsFormSections';
import { projectsFormSchema } from './schema';

export type ProjectsFormProps = {
  onSubmit: (project: Partial<Project>) => void;
  onChangeApiExportMappingFile?: (file?: File) => void;
};

// Container component for managing the whole form state.
export const ProjectsForm: FC<ProjectsFormProps> = ({ onSubmit, onChangeApiExportMappingFile }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const { project } = useFormProjectState();

  const projectsFormSchemaWithTranslations = projectsFormSchema({
    error: {
      name: t('entities.projects.fields.name.error'),
      onlySpaces: t('entities.projects.fields.name.errorOnlySpaces'),
      utmZone: t('entities.projects.fields.utmZone.error'),
      multipolygonGeoJson: t('entities.projects.fields.multipolygonGeoJson.error'),
      period: {
        startDate: t('entities.projects.fields.startDate.error'),
        endDate: t('entities.projects.fields.endDate.error'),
      },
      networkDesign: {
        state: t('entities.projects.fields.networkDesign.state.error'),
        format: t('entities.projects.fields.networkDesign.format.error'),
      },
      activityState: t('entities.projects.fields.activityState.error'),
      organizationId: t('entities.projects.fields.client.error'),
      customerContactPerson: {
        userId: t('entities.projects.fields.customerContactPerson.userId.error'),
      },
      contactPerson: {
        email: t('entities.projects.fields.contactPerson.email.error'),
      },
      customerManager: {
        email: t('entities.projects.fields.customerManager.email.error'),
      },
    },
  });
  const { data, error } = useOrganizationsApi();
  const { register, handleSubmit, control } = useForm<Partial<Project>>({
    resolver: yupResolver<Partial<Project>>(projectsFormSchemaWithTranslations),
    defaultValues: {
      period: {
        startDate: undefined,
        endDate: undefined,
      },
    },
  });
  const { errors: formErrors } = useFormState({ control });
  const { isItemEnabled } = useRenderConfig();
  const isFormEnabled = isItemEnabled('projects.edit.form.mode');
  const handleClickCancel = () => {
    navigate('/projects');
  };

  const formErrorHandler = (errors: FieldValues | undefined) => {
    if (errors) {
      showMessage({ message: t('common.missingData'), type: 'error' });
    }
  };

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  const { content: organizations } = data;

  return (
    <form onSubmit={handleSubmit(onSubmit, formErrorHandler)}>
      <Box display="flex" flexDirection="column">
        <ProjectsFormMasterData
          formErrors={formErrors}
          onChangeApiExportMappingFile={onChangeApiExportMappingFile}
          project={project}
          register={register}
        />

        <ProjectsFormConnections
          control={control}
          formErrors={formErrors}
          organizations={organizations}
          project={project}
        />

        <>
          <ProjectsFormNetworkDesign control={control} formErrors={formErrors} project={project} />

          <ProjectsFormActivityState control={control} formErrors={formErrors} project={project} />

          <ProjectsFormPipeDirection control={control} project={project} />

          <ProjectsFormCustomerContactPerson
            control={control}
            formErrors={formErrors}
            project={project}
          />

          <ProjectsFormCustomerManager
            formErrors={formErrors}
            project={project}
            register={register}
          />

          <ProjectsFormServiceProvider project={project} />
        </>

        <ProjectsFormContactPerson
          control={control}
          formErrors={formErrors}
          project={project}
          register={register}
        />

        <Box my={4} />
        <BottomFormNavigation
          hasNext={isFormEnabled}
          nextType="submit"
          onGoBack={handleClickCancel}
        />
      </Box>
    </form>
  );
};
