import { BriefcaseOutline, Checkmark, XOutline } from '@deepup/icons';
import { Box, Tooltip } from '@mui/material';
import {
  DataGrid,
  type GridColDef,
  type GridRowsProp,
  type GridRowParams,
  type GridRenderCellParams,
  type GridSortModel,
  type GridFilterModel,
  type GridPaginationModel,
  type GridColumnHeaderParams,
} from '@mui/x-data-grid';
import { useState, type FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { renderCellContent } from '@components/TableCell/TableCell';
import type { Organization } from '@hooks/api/organizations';
import type { ProjectWithOrganizations } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { getLocaleTextForDataGrid } from '@utils/getLocaleTextForDataGrid';

interface ProjectsTableProps {
  projects: ProjectWithOrganizations[];
  isLoading: boolean;
  page: number;
  size: number;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  sortModel: GridSortModel;
  rowCount: number;
  searchName?: string;
  networkOperatorId?: string;
  generalContractorId?: string;
}

export const ProjectsTable: FC<ProjectsTableProps> = ({
  projects,
  isLoading,
  page,
  size,
  sortModel,
  rowCount,
  onSizeChange,
  onPageChange,
  onSortModelChange,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isItemVisible } = useRenderConfig();
  const pageSizeOptions = [25, 50, 100];
  const { isPlatformAdmin } = usePlatformPermissions();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: page,
    pageSize: size,
  });

  const handlePaginationModelChange = useCallback(
    (newModel: GridPaginationModel) => {
      setPaginationModel(newModel);
      onPageChange(newModel.page);
      onSizeChange(newModel.pageSize);
    },
    [onPageChange, onSizeChange],
  );

  const handleSortModelChange = useCallback(onSortModelChange, [onSortModelChange]);

  const renderIcon = (params: GridRenderCellParams) => {
    const project = params.row as ProjectWithOrganizations;
    const key = params.field as keyof ProjectWithOrganizations;
    const orga = project[key] as Organization | undefined;

    if (!orga) return null;

    const highlightField = orga.id === project.organization?.id;
    const hasClientInfoIcon = isItemVisible('projects.overview.components.clientIcon');

    return (
      <Box alignItems="center" display="flex">
        {highlightField && hasClientInfoIcon && (
          <>
            <Tooltip
              arrow
              title={t('pages.projects.overview.components.table.tooltipProjectOwner')}
            >
              <Box flexShrink={0}>
                <BriefcaseOutline height={24} width={24} />
              </Box>
            </Tooltip>
            <Box mx={0.75} />
          </>
        )}
        <Box>{orga.name}</Box>
      </Box>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('entities.projects.fields.name.label'),
      width: 350,
      sortable: true,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'name',
        }),
    },
    {
      field: 'organization',
      headerName: t('entities.projects.fields.client.label'),
      width: 250,
      sortable: true,
      valueGetter: (params) => params.row.organization?.name || '',
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Organization">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'organization',
        }),
    },
    {
      field: 'networkProvider',
      headerName: t('entities.projects.fields.networkProvider.label'),
      width: 250,
      sortable: true,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-NetworkProvider">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'networkProvider',
          renderContent: (value) => renderIcon({ ...params, value }),
        }),
    },
    {
      field: 'generalContractor',
      headerName: t('entities.projects.fields.generalContractor.label'),
      width: 250,
      sortable: true,
      renderHeader: (params) => (
        <span data-testid="table-header-cell-GeneralContractor">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'networkProvider',
          renderContent: (value) => renderIcon({ ...params, value }),
        }),
    },
    {
      field: 'startDate',
      headerName: t('entities.projects.fields.startDate.label'),
      width: 150,
      sortable: true,
      valueGetter: (params) =>
        params.row.period?.startDate
          ? new Date(params.row.period.startDate).toLocaleDateString(undefined, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : '',
      renderHeader: (params) => (
        <span data-testid="table-header-cell-Start-Date">{params.colDef.headerName}</span>
      ),
      renderCell: (params) =>
        renderCellContent({
          entityType: 'projects',
          params,
          isLoading,
          columnField: 'startDate',
        }),
    },

    ...(isPlatformAdmin
      ? [
          {
            field: 'customerContactPerson',
            headerName: t('entities.projects.fields.customerContactPerson.label'),
            width: 250,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'customerContactPerson',
              }),
          },
          {
            field: 'customerManager',
            headerName: t('pages.projects.form.header.customerManager'),
            width: 250,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'customerManager',
              }),
          },
          {
            field: 'serviceProviders',
            headerName: t('entities.projects.fields.serviceProviders.label'),
            width: 250,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-ServiceProviders">
                {params.colDef.headerName}
              </span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'serviceProviders',
                renderContent: () => (
                  <Box>{params.row.serviceProviders?.map(({ name }) => name).join(', ')}</Box>
                ),
              }),
          },
          {
            field: 'networkDesignState',
            headerName: t('pages.projects.overview.components.table.networkDesignState'),
            width: 200,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'networkDesignState',
              }),
          },
          {
            field: 'networkDesignFormat',
            headerName: t('pages.projects.overview.components.table.networkDesignFormat'),
            width: 200,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'networkDesignFormat',
              }),
          },
          {
            field: 'activityState',
            headerName: t('pages.projects.overview.components.table.activityState'),
            width: 150,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'activityState',
              }),
          },
          {
            field: 'pipeDirection',
            headerName: t('entities.projects.fields.pipeDirection.label'),
            width: 150,
            sortable: true,
            renderHeader: (params: GridColumnHeaderParams<ProjectWithOrganizations>) => (
              <span data-testid="table-header-cell-Name">{params.colDef.headerName}</span>
            ),
            renderCell: (params: GridRenderCellParams<ProjectWithOrganizations>) =>
              renderCellContent({
                entityType: 'projects',
                params,
                isLoading,
                columnField: 'pipeDirecetion',
                renderContent: () => (
                  <Box alignItems="center" display="flex" justifyContent="center" width={100}>
                    {params.row.pipeDirection ? (
                      <Checkmark height={20} width={20} />
                    ) : (
                      <XOutline height={20} width={20} />
                    )}
                  </Box>
                ),
              }),
          },
        ]
      : []),
  ];

  const rows: GridRowsProp = projects.map((project) => ({
    id: project.id,
    name: project.name,
    organization: project.organization,
    networkProvider: project.networkProvider,
    generalContractor: project.generalContractor,
    period: project.period,
    customerContactPerson: project.customerContactPerson?.name,
    networkDesignState: project.networkDesign?.state,
    networkDesignFormat: project.networkDesign?.format,
    customerManager: project.customerManager?.name,
    activityState: project.activityState,
    pipeDirection: project.pipeDirection,
    serviceProviders: project.serviceProviders,
  }));

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/projects/${params.id}/overview`);
  };

  return (
    <Box sx={(theme) => ({ height: theme.spacing(50), width: '100%' })}>
      <DataGrid
        autoHeight
        columns={columns}
        data-testid="table-projects"
        disableColumnMenu
        filterMode="server"
        filterModel={filterModel}
        loading={isLoading}
        localeText={getLocaleTextForDataGrid(i18n.language)}
        onFilterModelChange={setFilterModel}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={pageSizeOptions}
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={rows}
        sortModel={sortModel}
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
      />
    </Box>
  );
};
