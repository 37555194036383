import type { GridSortModel } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params';

import { convertStringToSortModel } from '../utils/convertStringToSortModel';
import { serializeSortModel } from '../utils/serializeSortModel';

export const useProjectsUrlSync = () => {
  const [query, setQuery] = useQueryParams({
    name: StringParam,
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 100),
    sort: StringParam,
    networkProviderId: StringParam,
    generalContractorId: StringParam,
    customerManager: StringParam,
    customerContactPerson: StringParam,
    serviceProviderId: StringParam,
    networkDesign_state: StringParam,
    networkDesign_format: StringParam,
    activityState: StringParam,
    pipeDirection: StringParam,
  });

  const {
    page,
    size,
    name,
    sort,
    networkProviderId,
    generalContractorId,
    customerManager,
    customerContactPerson,
    serviceProviderId,
    networkDesign_state,
    networkDesign_format,
    activityState,
    pipeDirection,
  } = query;

  const sortModel: GridSortModel = useMemo(() => convertStringToSortModel(sort || ''), [sort]);

  const handlePageChange = (newPage: number) => setQuery({ page: newPage });
  const handleSizeChange = (newSize: number) => setQuery({ size: newSize });
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setQuery({ sort: serializeSortModel(newSortModel) });
  };
  const handleNameChange = (newSearchName: string) => setQuery({ name: newSearchName });
  const handleNetworkProviderIdChange = (newNetworkProviderId: string) =>
    setQuery({ networkProviderId: newNetworkProviderId });
  const handleGeneralContractorIdChange = (newGeneralContractorId: string) =>
    setQuery({ generalContractorId: newGeneralContractorId });
  const handleCustomerManagerChange = (newCustomerManager: string) =>
    setQuery({ customerManager: newCustomerManager });
  const handleCustomerContactPersonChange = (newCustomerContactPerson: string) =>
    setQuery({ customerContactPerson: newCustomerContactPerson });
  const handleServiceProviderIdChange = (newServiceProviderId: string) =>
    setQuery({ serviceProviderId: newServiceProviderId });
  const handleNetworkDesignStateChange = (newNetworkDesignState: string) =>
    setQuery({ networkDesign_state: newNetworkDesignState });
  const handleNetworkDesignFormatChange = (newNetworkDesignFormat: string) =>
    setQuery({ networkDesign_format: newNetworkDesignFormat });
  const handleActivityStateChange = (newActivityState: string) =>
    setQuery({ activityState: newActivityState });
  const handlePipeDirectionChange = (newPipeDirection: string) => {
    setQuery({ pipeDirection: newPipeDirection });
  };

  return {
    page,
    size: size,
    sortModel,
    name: name || '',
    networkProviderId: networkProviderId || '',
    generalContractorId: generalContractorId || '',
    customerManager: customerManager || '',
    customerContactPerson: customerContactPerson || '',
    serviceProviderId: serviceProviderId || '',
    networkDesignState: networkDesign_state || '',
    networkDesignFormat: networkDesign_format || '',
    activityState: activityState || '',
    pipeDirection: pipeDirection || '',
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
    handleNetworkProviderIdChange,
    handleGeneralContractorIdChange,
    handleCustomerManagerChange,
    handleCustomerContactPersonChange,
    handleServiceProviderIdChange,
    handleNetworkDesignStateChange,
    handleNetworkDesignFormatChange,
    handleActivityStateChange,
    handlePipeDirectionChange,
  };
};
