import {
  Autocomplete,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Controller, type Control, type DeepRequired, type FieldErrorsImpl } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';

interface ProjectsFormActivityStateProps {
  formErrors: FieldErrorsImpl<DeepRequired<Project>>;
  project?: Project;
  control: Control<Partial<Project>>;
}

export const ProjectsFormActivityState = ({
  project,
  formErrors,
  control,
}: ProjectsFormActivityStateProps) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();

  const isFormEnabled = isItemEnabled('projects.edit.form.mode');

  const activityStates = [
    { id: 'NOT_STARTED', name: t('entities.projects.fields.activityState.states.notStarted') },
    { id: 'PAUSED', name: t('entities.projects.fields.activityState.states.paused') },
    { id: 'ACTIVE', name: t('entities.projects.fields.activityState.states.active') },
    { id: 'FINISHED', name: t('entities.projects.fields.activityState.states.finished') },
    { id: 'UNKNOWN', name: t('entities.projects.fields.activityState.states.unknown') },
  ];
  const currentState = activityStates.find((format) => format.id === project?.activityState);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const inputWidth = isDesktop ? '50%' : '100%';

  return (
    <Box mb={2}>
      <FormSectionHeader title={t('pages.projects.form.header.activityState')} />
      <Stack width={inputWidth}>
        <FormControl disabled={!isFormEnabled}>
          <Controller
            control={control}
            defaultValue={currentState?.id}
            name={'activityState'}
            render={({ field: { onChange } }) => (
              <Autocomplete
                defaultValue={currentState}
                disabled={!isFormEnabled}
                fullWidth
                getOptionLabel={(p) => p.name}
                onChange={(_, value) => {
                  onChange(value?.id);
                }}
                options={activityStates}
                renderInput={(params) => (
                  <TextField
                    label={t('entities.projects.fields.activityState.label')}
                    {...params}
                    error={!!formErrors.networkDesign?.state}
                    helperText={
                      <Box component="span">
                        <Typography component="span" variant="body2">
                          {formErrors.networkDesign?.state?.message?.toString()}
                        </Typography>
                      </Box>
                    }
                    placeholder={t('common.pleaseChoose')}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </Stack>
    </Box>
  );
};
