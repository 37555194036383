import Box from '@mui/material/Box';
import type { DeepRequired, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput, FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';

interface ProjectsFormCustomerManager {
  register: UseFormRegister<Partial<Project>>;
  formErrors: FieldErrorsImpl<DeepRequired<Project>>;
  project?: Project;
}

export const ProjectsFormCustomerManager = ({
  project,
  formErrors,
  register,
}: ProjectsFormCustomerManager) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();

  const isFormEnabled = isItemEnabled('projects.edit.form.mode');

  return (
    <Box>
      <FormSectionHeader title={t('pages.projects.form.header.customerManager')} />
      <FormInput<Partial<Project>>
        defaultValue={project?.customerManager?.name}
        disabled={!isFormEnabled}
        error={formErrors.customerManager?.name}
        formFieldName="customerManager.name"
        label={t('entities.projects.fields.customerManager.name.label')}
        register={register}
      />
      <FormInput<Partial<Project>>
        defaultValue={project?.customerManager?.email}
        disabled={!isFormEnabled}
        error={formErrors.customerManager?.email}
        formFieldName="customerManager.email"
        label={t('entities.projects.fields.customerManager.email.label')}
        register={register}
      />
    </Box>
  );
};
